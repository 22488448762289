<template>
  <div>
    <div class="chat-content h-100">
      <div class="chat-body" v-if="activeTab === 'chat'">
        <div class="messages-container h-100" ref="messagesContainer">
          <div v-for="(message, index) in messages" :key="index" :class="['message', message.sender]">
            <div class="message-content">
              <div v-if="message.sender === 'agent'" class="agent-info">
                <font-awesome-icon :icon="['fal', 'message-bot']" size="2x" />
                <span class="agent-name pl-1">{{ $t("chatbot") }}</span>
                <span class="message-time">{{ message.timestamp }}</span>
              </div>
              <div class="message-text">{{ message.text }}</div>
            </div>
          </div>
        </div>
        <div class="chat-input-container">
          <input v-model="userInput" @keyup.enter="sendMessage" @focus="inputFocused = true"
            @blur="inputFocused = false" placeholder="Type hier uw bericht..." class="chat-input" />
          <div class="input-actions">
            <button v-if="!inputFocused || userInput.trim() == ''" class="emoji-button">
              <font-awesome-icon :icon="['fal', 'face-smile-beam']" size="lg" />
            </button>
            <button v-if="!inputFocused || userInput.trim() == ''" class="emoji-button">
              <font-awesome-icon :icon="['fal', 'paperclip']" size="lg" />
            </button>
            <button v-if="inputFocused || userInput.trim() !== ''" class="emoji-button" @mousedown.prevent
              @click="sendMessage">
              <font-awesome-icon :icon="['fal', 'paper-plane']" size="lg" />
            </button>
          </div>
        </div>
      </div>

      <div class="form-container bg-white px-5" v-else-if="activeTab === 'support'">
        <div class="form-group">
          <label class="text-dark m-0" for="name">{{ $t("form_name") }}</label>
          <input type="text" id="name" :placeholder="$t('form_name')" class="form-input" v-model="formData.name" />
        </div>
        <div class="form-group">
          <label class="text-dark" for="email">{{ $t("email_address") }}</label>
          <input type="text" id="email" :placeholder="$t('email_address')" class="form-input" v-model="formData.email" />
        </div>
        <div class="form-group">
          <label class="text-dark" for="phone">{{ $t("form_phone") }}</label>
          <input type="text" id="phone" :placeholder="$t('form_phone')" class="form-input" v-model="formData.phone" />
        </div>
        <div class="form-group">
          <label class="text-dark" for="orderNumber">{{ $t("order_no") }}</label>
          <input type="text" id="orderNumber" :placeholder="$t('order_no')" class="form-input"
            v-model="formData.orderNumber" />
        </div>
        <div class="form-group">
          <label class="text-dark" for="message">{{ $t("message") }}</label>
          <textarea id="message" :placeholder="$t('message')" class="form-textarea" v-model="formData.message"></textarea>
        </div>
        <button class="btn btn-primary w-75 mx-auto">
          Submit
        </button>
      </div>

    </div>

    <div class="chat-tabs">
      <button :class="{ 'active': activeTab === 'chat' }" @click="activeTab = 'chat'">
        <font-awesome-icon :icon="['fal', 'message-bot']" size="2x" />
      </button>
      <button :class="{ 'active': activeTab === 'support' }" @click="activeTab = 'support'">
        <font-awesome-icon :icon="['fal', 'messages-question']" size="2x" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
      isMobile: false,
      activeTab: 'support',
      userInput: '',
      inputFocused: false,
      formData: {
        name: '',
        email: '',
        phone: '',
        orderNumber: '',
        message: ''
      },
      messages: [
        {
          text: this.$t('bot_answer'),
          sender: 'agent',
          timestamp: this.formatTime()
        }
      ]
    };
  },
  mounted() {
    this.checkMobile();
  },
  methods: {
    toggleChat() {
      this.isOpen = !this.isOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 992;
    },
    sendMessage() {
      if (!this.userInput.trim()) return;
      // Add user message
      this.messages.push({
        text: this.userInput,
        sender: 'user'
      });
      this.userInput = '';
      this.scrollToBottom();
      setTimeout(() => {
        this.messages.push({
          text: this.$t('bot_answer2'),
          sender: 'agent',
          timestamp: this.formatTime()
        });
        this.scrollToBottom();
      }, 1000);
    },
    formatTime() {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    submitForm() {
      console.log('Form submitted:', this.formData);

      this.formData = {
        name: '',
        email: '',
        phone: '',
        orderNumber: '',
        message: ''
      };
    },
    updateTime() {
      this.currentTime = new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  },
};
</script>