<template>
  <header class="header header-22">
    <div class="header-middle">
      <div :class="isFullwidth ? 'container-fluid' : 'container'">
        <div class="header-left">
          <router-link to="/" class="logo">
            <img
              src="@/esf_weert_mobilesupplies/assets/static/images/logo.png"
              class="bg-transparent"
              alt="Mobile Supplies"
            />
          </router-link>
        </div>

        <div class="header-right">
          <div class="d-block d-md-none">
            <font-awesome-icon icon="fa-solid fa-star" class="text-primary" />
            {{ $t("15_years_of_experience") }}
          </div>
          <div class="d-none d-lg-block">
            <header-search></header-search>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-nav-actions">
      <div
        class="d-flex align-items-center justify-content-between"
        v-if="isSearch"
      >
        <font-awesome-icon
          style="color: white; font-size: 18px; margin-right: 10px"
          icon="fa-solid fa-arrow-left"
          @click="onSearch"
        />
        <header-search></header-search>
      </div>
      <div class="d-flex justify-content-between" v-else>
        <button class="mobile-menu-toggler" @click="openMobileMenu">
          <span class="sr-only">Toggle mobile menu</span>
          <i class="icon-times" style="-webkit-text-stroke: 3px white"></i>
          <i class="icon-bars"></i>
        </button>
        <div class="mobile-nav-actions-icons">
          <font-awesome-icon icon="fa-solid fa-search" @click="onSearch" />
          <router-link :to="isLoggedIn ? '/account/' : '/login/'">
            <font-awesome-icon icon="fa-solid fa-user" />
          </router-link>
          <!-- <font-awesome-icon icon="fa-solid fa-shopping-cart" /> -->
          <cart-menu></cart-menu>
        </div>
      </div>
    </div>

    <sticky-header>
      <div class="wrap-container sticky-header">
        <div class="header-bottom">
          <div :class="isFullwidth ? 'container-fluid' : 'container'">
            <div class="header-center">
              <main-menu></main-menu>
            </div>

            <div class="header-right">
              <div class="header-text">
                <ul class="top-menu top-link-menu">
                  <li>
                    <ul>
                      <li>
                        <router-link
                          to="/account/"
                          class="d-flex flex-column"
                          v-if="isLoggedIn"
                        >
                          <!-- <i class="icon-user"></i> -->
                          <img
                            src="@/esf_weert_mobilesupplies/assets/static/images/icons/user.png"
                            width="20"
                            alt="user"
                          />
                          <p class="text-capitalize">{{ $t("account") }}</p>
                        </router-link>
                        <router-link
                          to="/login/"
                          class="d-flex flex-column"
                          v-else
                        >
                          <!-- <i class="icon-user"></i> -->
                          <img
                            src="@/esf_weert_mobilesupplies/assets/static/images/icons/user.png"
                            width="20"
                            alt="user"
                          />
                          <p class="text-capitalize">
                            {{ $t("login_signup") }}
                          </p>
                        </router-link>
                      </li>
                      <li>
                        <cart-menu></cart-menu>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </sticky-header>
  </header>
</template>

<script>
import CartMenu from "./shared/CartMenu";
import MainMenu from "./shared/MainMenu";
import HeaderSearch from "./shared/HeaderSearch";
import StickyHeader from "../../elements/StickyHeader.vue";

// extra
// import WishlistMenu from './shared/WishlistMenu';
// import CompareMenu from './shared/CompareMenu';

export default {
  components: {
    CartMenu,
    MainMenu,
    HeaderSearch,
    StickyHeader,
    // WishlistMenu,
    // CompareMenu,
  },
  data: () => {
    return {
      isSearch: false,
      isOpen: false,
    };
  },
  computed: {
    isFullwidth: function () {
      return false;
      // return this.$route.path.includes('fullwidth');
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    // openSignInModal: function () {
    // 	this.$modal.show(
    // 		() => import('../../elements/modals/SignInModal.vue'),
    // 		{},
    // 		{ width: '575', height: 'auto', adaptive: true }
    // 	);
    // },
    openMobileMenu: function () {
      document.querySelector("body").classList.add("mmenu-active");
      this.isOpen = !this.isOpen;
    },
    onSearch: function () {
      this.isSearch = !this.isSearch;
      // console.log('search');
    },
  },
};
</script>
