<template>
  <div>
    <div class="container-fluid service">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="icon-box icon-box-side">
              <BlockSimple identifier="footer_usp_1" />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="icon-box icon-box-side">
              <BlockSimple identifier="footer_usp_2" />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="icon-box icon-box-side">
              <BlockSimple identifier="footer_usp_3" />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="icon-box icon-box-side">
              <BlockSimple identifier="footer_usp_4" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newsletter">
      <div class="container">
        <div class="subscribe text-center">
          <!-- <div class="intro"> -->
          <!-- <h4 class="content">Learn about new offers and get more deals by joining our newsletter</h4> -->
          <!-- </div> -->
          <div class="subscribe action d-md-flex d-sm-flex-none">
            <h3 class="title">{{ $t("alenmelden_newsletter") }}</h3>
            <form @submit.prevent="submitNewsletter">
              <div class="input-group">
                <input type="email" v-model="newsletterEmail" :placeholder="$t('email_address_login_form')"
                  aria-label="Email Address" required :disabled="isSubscribing" />
                <div class="input-group-append">
                  <button class="btn btn-subscribe" type="submit" :disabled="isSubscribing">
                    <span v-show="!isSubscribing">{{ $t("subscribe_newsletter") }}</span>
                    <span v-show="isSubscribing"><i class="fas fa-spinner fa-spin"></i></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="footer-middle">
        <div class="container">
          <div class="row g-5">
            <div class="col-sm-6 col-lg-4 footer-info">
              <div class="widget">
                <BlockSimple identifier="contactinformatie" />
              </div>
              <!-- <div class="widget widget-about" v-html="mobileFooterLinks.content" /> -->
            </div>

            <div class="col-sm-6 col-lg-4 footer-info">
              <div class="widget">
                <BlockSimple identifier="mobile_footerlinks" />
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="widget">
                <BlockSimple identifier="brands_overview" />
              </div>
            </div>

            <!-- <div class="col-md-12">
              <div class="widget">
               
                <h4 class="widget-title">{{ $t("products") }}</h4>

                <div class="row widget-list">
                  <div class="col-md-2 col-4">
                    <router-link to="">Sign In</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <router-link to="/shopping-cart/">View Cart</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <router-link to="">My Wishlist</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <router-link to="">Track My Order</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <router-link to="">Help</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <router-link to="/merken/">{{ $t("brands") }}</router-link>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                  <div class="col-md-2 col-4">
                    <a href="#">category</a>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-12 mt-2">
              <div class="widget bottom_info">
                <BlockSimple identifier="bottom_info" />
              </div>
            </div>
            <!-- <div class="col-md-12 d-flex justify-content-center">
              <img src="@/esf_weert_mobilesupplies/assets/static/images/payments.png" class="bg-transparent"
                alt="Payment methods" width="350" height="20" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="footer-bottom">

        <p class="footer-copyright"> {{ $t("prices_include_vat") }}</p>
        <p class="footer-copyright">
          <router-link to="/algemene-voorwaarden">{{ $t("terms_and_conditions") }}</router-link> -
          <router-link to="/privacy-policy-cookie-restriction-mode">{{ $t("Privacy") }}</router-link> -
          <router-link to="" @click.native="showCookies">{{ $t("Cookies") }}</router-link> -

          {{ $t("privacy_copyright_footer", { year: currentYear }) }}
        </p>
        <p class="footer-copyright">{{ $t("footer_trademark") }}</p>
      </div>
      <!-- End .footer-bottom -->
      <div class="mb-10" v-if="bottomSticky"></div>
    </footer>
    <!-- End .footer -->
  </div>
</template>

<script>
import BlockSimple from "@/esf_weert_mobilesupplies/core/components/elements/BlockSimple";
import { newsletterSignup } from "@storefront/core/data-resolver/user";

export default {
  data: function () {
    return {
      bottomSticky: false,
      currentYear: new Date().getFullYear(),
      newsletterEmail: "",
      isSubscribing: false,
      isSubscribed: false
    };
  },
  components: { BlockSimple },
  computed: {
    isFullwidth: function () {
      return this.$route.path.includes("fullwidth");
    },
    // mobileSeoFooterlinks() {
    // 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    // 		"mobile_seo_footerlinks"
    // 	);
    // },
    // mobileFooterLinks() {
    // 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    // 		"mobile_footerlinks"
    // 	);
    // },
    // bottomInfo() {
    // 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    // 		"bottom_info"
    // 	);
    // },
  },
  watch: {
    $route: function () {
      this.handleBottomSticky();
    },
  },
  mounted: function () {
    this.handleBottomSticky();
    window.addEventListener("resize", this.handleBottomSticky, {
      passive: true,
    });
  },
  unmounted: function () {
    window.removeEventListener("resize", this.handleBottomSticky);
  },
  methods: {
    async submitNewsletter() {
      if (!this.newsletterEmail) return;
      
      this.isSubscribing = true;
      
      try {
        const response = await newsletterSignup(this.newsletterEmail);
        
        if (response === "SUBSCRIBED") {
          const msg = {
            type: "success",
            title: this.$t("newsletter"),
            text: this.$t("subscription_succesful") || "Successfully subscribed to newsletter!"
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          
          this.isSubscribed = true;
          this.newsletterEmail = "";
        }
        
        setTimeout(() => {
          this.isSubscribing = false;
        }, 1000);
        
      } catch (error) {
        console.error("Newsletter signup error:", error);
        
        setTimeout(() => {
          this.isSubscribing = false;
        }, 1000);
      }
    },
    showCookies() {
      this.$root.cookiesAccepted = false;
      let cc = document.getElementById('cookies')
      if (cc) {
        cc.style.display = "block";
      }
    },
    handleBottomSticky: function () {
      this.bottomSticky =
        this.$route.path.includes("/product/default") &&
        window.innerWidth > 991;
    },
  },
};
</script>
