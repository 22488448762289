<template lang="html">
    <div>
        <div class="page-wrapper">
            <!-- <language-switch></language-switch> -->
            <header-default></header-default>
            <router-view />
            <footer-default></footer-default>
             <!--<button v-if="!isChatOpen" id="support" title="Support" @click="toggleChat" class="d-none d-md-block">
                <div class="content">
                    <img src="@/esf_weert_mobilesupplies/assets/static/images/call-agent.png" alt="agent" />
                    <div>
                        <p class="text-dark">Support</p>
                        <p class="text-primary">Mobile Supplies</p>
                    </div>
                </div>
                <i class="icon-arrow-up"></i> 
            </button>-->
            <div v-if="isChatOpen" class="chat-popup">
                <div class="chat-header">
                    <div class="header-content">
                        <div class="header-text">
                            <div class="text-dark support-subtitle">
                                {{ $t("orderQuestion") }}
                            </div>
                        </div>
                    </div>
                    <button class="close-button d-none d-md-flex" @click="toggleChat">
                        <font-awesome-icon :icon="['faS', 'xmark']" size="sm" />
                    </button>
                    <button class="close-button d-md-none d-flex ml-2 mb-4 h-25 rounded" @click="toggleChat">
                        <font-awesome-icon :icon="['fas', 'xmark']" size="sm" />
                    </button>
                </div>
                <div class="chat-body">
                    <Chat />
                </div>
            </div>

            <button id="scroll-top" ref="scrollTop" title="Back to Top" @click.prevent="scrollTop">
                <i class="icon-arrow-up"></i>
            </button>
            <router-link to="/compare">
                <button class="btn btn-success" id="compare-float-button" v-if="compareProducts.length > 0">
                    Vergilijk {{ compareProducts.length }} product(en)
                </button>
            </router-link>
            <cookies></cookies>
        </div>
        <div class="mobile-menu-overlay" @click="hideMobileMenu"></div>
        <mobile-menu :isChatOpen.sync="isChatOpen"></mobile-menu>
    </div>
</template>

<script>
// import LanguageSwitch from '@/esf_weert_mobilesupplies/core/components/elements/LanguageSwitch';
import Cookies from '@/esf_weert_mobilesupplies/core/components/elements/Cookies';
import HeaderDefault from '@/esf_weert_mobilesupplies/core/components/partial/headers/HeaderDefault';
import FooterDefault from '@/esf_weert_mobilesupplies/core/components/partial/footers/FooterDefault';
import Chat from '@/esf_weert_mobilesupplies/core/components/elements/Chat';
import { isSafariBrowser, isEdgeBrowser } from '@/esf_weert_mobilesupplies/utilities/common';

export default {
    data() {
        return {
            isChatOpen: false,
        };
    },
    components: {
        // LanguageSwitch,
        Cookies,
        HeaderDefault,
        FooterDefault,
        Chat,
        MobileMenu: () => import('@/esf_weert_mobilesupplies/core/components/partial/home/MobileMenu')
    },
    computed: {
        compareProducts() {
            return this.$store.getters["product/getProductCompare"]
        }
    },
    mounted: function () {
        // Adjust Trengo chatbot position dynamically
    // document.querySelector('.TrengoWidgetLauncher__iframe').style.right = '10px';

        let scrollTop = this.$refs.scrollTop;
        document.addEventListener(
            'scroll',
            function () {
                if (window.pageYOffset >= 400) {
                    scrollTop.classList.add('show');
                } else {
                    scrollTop.classList.remove('show');
                }
            },
            false
        );

        // setTimeout(() => {
        //     let cc = document.getElementById('cookies')
        //     cc.classList.add('show');
        // }, 2000)
    },
    methods: {
        scrollTop: function () {
            if (isSafariBrowser() || isEdgeBrowser()) {
                let pos = window.pageYOffset;
                let timerId = setInterval(() => {
                    if (pos <= 0) clearInterval(timerId);
                    window.scrollBy(0, -120);
                    pos -= 120;
                }, 1);
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        },
        toggleChat() {
            if (this.isChatOpen) {
                const chat = document.querySelector('.chat-popup');
                if (chat) {
                    chat.classList.add('hide');
                    setTimeout(() => {
                        this.isChatOpen = !this.isChatOpen;
                    }, 300);
                }
            } else
                this.isChatOpen = !this.isChatOpen;
        },
    }
};
</script>